<script lang="ts">
    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';
    import 'tippy.js/animations/scale.css';

    import { onMount } from 'svelte';
    // export let item;
    export let title = 'POST TEXT';

    export let creatorName = 'Creator Name';
    export let posts = 3;
    export let totalPosts = 60;
    export let currentPrice = 200;
    export let listed = 'Not owned';

    const itemStats = [
        {
            activity: 'List',
            seller: 'User1',
            buyer: '-',
            price: '200 P',
            time: '19.12.2023',
        },
        {
            activity: 'Sale',
            seller: 'User1',
            buyer: 'User2',
            price: '150 P',
            time: '19.12.2023',
        },
        {
            activity: 'List',
            seller: 'User1',
            buyer: '-',
            price: '150 P',
            time: '19.12.2023',
        },
        {
            activity: 'Initial Sale',
            seller: 'User1',
            buyer: '-',
            price: '20 P',
            time: '19.12.2023',
        },
    ];

    onMount(() => {
        tippy('.tooltip-time', {
            content: (reference) => reference.getAttribute('data-tippy-content') ?? '',
            placement: 'top',
            animation: 'scale',
            theme: 'custom',
            arrow: true,
            duration: [300, 250],
            delay: [100, 50],
        });
    });
</script>

<div class="col-12 min-vh-100 border-right pt-4">
    <div class="p-3">
        <div class="row rounded-lg bg-white shadow-lg dark:!bg-[#1E1E1E]">
            <div class="w-2/5">
                <a href="https://imgbb.com/" class="h-full"
                    ><img
                        src="https://i.ibb.co/d757cCs/debra-fiscal-1.png"
                        alt="debra-fiscal-1"
                        class="h-full w-full"
                    /></a
                >
            </div>

            <div class="w-3/5 p-4">
                <div>
                    <h5 class="font-bold text-black dark:!text-white">
                        {title}
                    </h5>
                    <p class="text-xs text-black dark:!text-white">
                        created by <span class="text-[#907DFF]">{creatorName}</span>
                    </p>
                    <span class="rounded-lg bg-[#00FFA3] bg-opacity-15 p-1.5 text-xs text-[#00FFA3]"
                        >Limited Edition</span
                    >
                    <p class="mt-2 p-1 text-xs text-[#00FFA3] text-opacity-40">
                        Number : <span class="font-bold text-[#00FFA3] !text-opacity-100">{posts}/{totalPosts}</span>
                    </p>
                </div>

                <div>
                    <span class="text-xs">Current price</span>
                    <p class="font-bold text-black dark:!text-white">
                        {listed === 'Not owned'
                            ? `${currentPrice} P`
                            : listed === 'Not Listed'
                              ? 'Not Listed'
                              : 'Owned By You'}
                    </p>
                    <button class="h-8 w-80 rounded-lg border-none bg-indigo-600 text-sm font-light text-white"
                        >Buy Now</button
                    >
                </div>

                <div class="mt-1">
                    <div class="overflow-x-auto">
                        <div class="inline-block !h-64 min-w-full py-1 align-middle">
                            <table class="min-w-full border-separate border-spacing-y-3">
                                <thead class="">
                                    <tr>
                                        <th
                                            scope="col"
                                            class="w-1/5 px-3 py-2 text-left text-[11px] font-semibold text-black dark:!text-white"
                                        >
                                            Activity
                                        </th>
                                        <th
                                            scope="col"
                                            class="w-1/5 px-2 py-2 text-center text-[11px] font-semibold text-black dark:!text-white"
                                        >
                                            Seller
                                        </th>
                                        <th
                                            scope="col"
                                            class="w-1/5 px-2 py-2 text-center text-[11px] font-semibold text-black dark:!text-white"
                                        >
                                            Buyer
                                        </th>
                                        <th
                                            scope="col"
                                            class="w-1/5 px-2 py-2 text-center text-[11px] font-semibold text-black dark:!text-white"
                                        >
                                            Price
                                        </th>
                                        <th
                                            scope="col"
                                            class="w-1/5 py-2 text-center text-[11px] text-black dark:!text-white"
                                        >
                                            Time
                                        </th>
                                    </tr>
                                </thead>

                                <tbody class="my-3 divide-y divide-gray-200">
                                    {#each itemStats as stats, index (stats)}
                                        <tr class="bg-[#474747] bg-opacity-5 dark:!bg-[#D9D9D9] dark:!bg-opacity-5">
                                            <td
                                                class="mb-3 w-1/5 whitespace-nowrap rounded-bl-lg rounded-tl-lg px-3 text-left text-[11px] font-medium text-black dark:!text-white"
                                            >
                                                {stats.activity}
                                            </td>
                                            <td
                                                class="mb-3 w-1/5 whitespace-nowrap px-2 py-2 text-center text-[11px] text-[#907DFF]"
                                                >{stats.seller}</td
                                            >
                                            <td
                                                class="mb-3 w-1/5 whitespace-nowrap px-2 py-2 text-center text-[11px] text-[#907DFF]"
                                                >{stats.buyer}</td
                                            >
                                            <td
                                                class="mb-3 w-1/5 whitespace-nowrap px-2 py-2 text-center text-[11px] font-bold text-black dark:!text-white"
                                                >{stats.price}</td
                                            >
                                            <td
                                                class="tooltip-time mb-3 w-1/5 whitespace-nowrap rounded-br-lg rounded-tr-lg px-2 py-2 text-center text-[11px] text-black dark:!text-white"
                                                data-tippy-content="{`Time: ${stats.time}`}">{stats.time}</td
                                            >
                                        </tr>
                                    {/each}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .tippy-box[data-theme~='custom'] {
        background-color: #aa0e0e55;
        color: #fff !important;
        border-radius: 8px;
        padding: 8px;
    }
    .tippy-box[data-theme~='custom'] .tippy-arrow {
        color: #333;
    }
</style>
